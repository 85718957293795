<template>
  <ValidationObserver
    ref="updateGroupType"
    v-slot="{ invalid }"
    v-if="selectedTypeBenefit"
  >
    <b-form @submit.prevent="update">
      <b-row>
        <b-col md="3">
          <ValidationProvider rules="required" name="nombre">
            <b-form-group label="Nombre" slot-scope="{ valid, errors }">
              <b-form-input
                class="form-control"
                type="text"
                v-model="selectedTypeBenefit.name"
                :state="errors[0] ? false : valid ? true : null"
              >
              </b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        
          <b-col md="3">
          <div class="float-left pt-2 mt-1">
            <b-form-checkbox
              v-model="selectedTypeBenefit.status"
              :value="true"
              class="custom-control-success"
            >
              Activo
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="float-right">
            <b-button
              type="submit"
              class="btn-block"
              variant="primary"
              :disabled="invalid || isUpdatingTypeBenefit"
            >
              <b-spinner small v-if="isUpdatingTypeBenefit" /> Actualizar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { showAlertMessage } from '@/helpers/helpers'

export default {
  data() {
    return {
      isUpdatingTypeBenefit: false,
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['selectedTypeBenefit']),
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['updateTypeBenefit']),
    ...mapMutations('fivesClubCatalogs', ['setSelectedTypeBenefit']),
    async update() {
      this.isUpdatingTypeBenefit = true;
      const payload = {
        idUser: this.user.idUser,
        id: this.selectedTypeBenefit.id,
        name: this.selectedTypeBenefit.name,
        status: Boolean(this.selectedTypeBenefit.status),
      }
      const { status, message } = await this.updateTypeBenefit(payload)
      if (status) {
        showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
        this.setSelectedTypeBenefit(null);
        this.$emit('set-tab-index', 0); //go to tab 1, to show list
      }
      this.isUpdatingTypeBenefit = false
    },
  },
};
</script>
