<template>
      
  <b-table responsive="sm" :items="typesBenefits" :fields="fields" class="mb-0" :tbody-tr-class="rowClass">
    <template #cell(code)="data">
      <span class="text-nowrap">
        {{ data.value ? "" : "N/A" }}
      </span>
    </template>

    <template #cell(status)="row">
      <span class="text-nowrap">        
        <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
          {{ row.item.status ? "Activo" : "Desactivado" }}
        </b-badge>
      </span>
    </template>
    <!-- para Status -->
    <template #cell(actions)="row">
      <div>
        <b-button
          size="sm"
          @click="setTypeBenefitToEdit(row.item)"          
          variant="primary"
         :class="{ 'd-none': !row.item.status || row.item.isLoading }"          
        >
          <feather-icon icon="Edit2Icon" size="12" />
        </b-button>
        <b-form-checkbox
         :class="{ 'd-none': row.item.status }"
          v-model="row.item.status"
          v-b-tooltip.hover.v-warning
          title="Cambiar Status"
          :value="true"
          switch
          inline
          :unchecked-value="false"
          @change="changeStatus(row.item, $event)"
          class="custom-control-success"
        >
        </b-form-checkbox>
         <b-spinner label="Loading..." variant="success" v-if="row.item.isLoading"/>
      </div>
    </template>
  </b-table>
</template>

<script>
import {  mapState, mapActions, mapMutations } from 'vuex'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { showAlertMessage } from '@/helpers/helpers'

export default {
  mixins: [utils],
  data() {
    return {
      fields: [
        { key: 'name',label: 'Nombre', class: 'text-center'},
        { key: 'status', label: 'Status', class: 'text-center' },
        { key: 'actions', label: 'Acciones' },
      ],
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['typesBenefits']),
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapMutations('fivesClubCatalogs', ['setSelectedTypeBenefit']),
    ...mapActions('fivesClubCatalogs', ['updateTypeBenefit']),

    setTypeBenefitToEdit(data) {
      this.$emit('set-tab-index', 2);
      this.setSelectedTypeBenefit(data);
    },
    async changeStatus(item, event){
      item.isLoading = true
      const payload = {
        idUser: this.user.idUser,
        id: item.id,
        name: item.name,
        status: Boolean(event),
      };
      const { status, message } = await this.updateTypeBenefit(payload)
      item.isLoading = false
      if (status) {
        showAlertMessage('Ok: Status actualizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )        
      }
    }
  },
};
</script>