<template>
    <ValidationObserver ref="createGroupTypeBenefit" v-slot="{ invalid }" >        
        <b-form @submit.prevent="saveTypeBen">
            <b-row>
                <b-col md="3">
                    <ValidationProvider rules="required" name="nombre">
                        <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                            <b-form-input                                
                                class="form-control"
                                type="text"
                                v-model="name"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>   
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                
             
          <b-col md="3">
          <div class="float-left pt-2 mt-1">
            <b-form-checkbox
              v-model="status"
              :value="true"
              class="custom-control-success"
            >
              Activo
            </b-form-checkbox>
          </div>
        </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="float-right">
                        <b-button type="submit" class="btn-block" variant="primary"  :disabled="invalid || isSavingTypeBenefit"                        
                        > 
                        <b-spinner small v-if="isSavingTypeBenefit"/> Guardar
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            
		</b-form>
    </ValidationObserver>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {  showAlertMessage } from '@/helpers/helpers' 

export default {
 
  data() {
    return {    
        name:'',
        status: true,
        isSavingTypeBenefit: false
    }
  },
  computed: {   
    ...mapState('auth',['user']),    
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['saveTypeBenefit','fetchTypesBenefits']),
    ...mapMutations('fivesClubCatalogs', ['setTypesBenefits']),
    async saveTypeBen(){ 
        this.isSavingTypeBenefit = true       
        const payload = {
            idUser: this.user.idUser,
            name: this.name,
            status: Boolean(this.status),
        } 
        const { status, message } = await this.saveTypeBenefit( payload )
        if(status){
            showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
            Object.assign( this.$data, this.$options.data() ) 
            this.$refs.createGroupTypeBenefit.reset() //reseteo form
            //vuelvo a pedir la info del back
            const typesBenefits  = await this.fetchTypesBenefits()
            this.setTypesBenefits(typesBenefits)
            this.$emit('set-tab-index', 0) 
        }
        this.isSavingTypeBenefit = false          
    },

  },
};
</script>
