<template>
  <b-card>
    <b-tabs v-model="tabIndex">
      <b-tab title="Tipos" @click="clickTab">
        <ListTypeBenefits @set-tab-index="setTabIndex" />
      </b-tab>
      <b-tab title="Crear" @click="clickTab">
        <TypeBenefitCreate @set-tab-index="setTabIndex" />
      </b-tab>

      <b-tab>
        <template #title>
          <div :class="selectedTypeBenefit ? '' : 'd-none'">Actualizar</div>
        </template>
        <TypeBenefitEdit @set-tab-index="setTabIndex" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ListTypeBenefits from "@/modules/fivesClub/components/catalogs/typesBenefits/ListTypeBenefits";
import TypeBenefitCreate from "@/modules/fivesClub/components/catalogs/typesBenefits/TypeBenefitCreate";
import TypeBenefitEdit from "@/modules/fivesClub/components/catalogs/typesBenefits/TypeBenefitEdit";

export default {
  components: {
    ListTypeBenefits,
    TypeBenefitCreate,
    TypeBenefitEdit,
  },
  created() {},
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs',['selectedTypeBenefit']),
  },
  methods: {
    ...mapMutations('fivesClubCatalogs',['setSelectedTypeBenefit']),
    setTabIndex( index ){
      this.tabIndex = index
    },
    clickTab() {
      this.setSelectedTypeBenefit(null);
    },
  },
};
</script>
